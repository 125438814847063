import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import TabSearchFontName from 'components/tabs/TabSearchFontName';
import TabSearchImage from 'components/tabs/TabSearchImage';
import TabSearchURL from 'components/tabs/TabSearchURL';
import { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Colors from 'utils/Colors';
import TabSearchBottom from './TabSearchBottom';

const FontTabMode = styled(Tab)<{ selected?: boolean }>(({ theme, ...props }) => ({
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  marginRight: 2,
  marginLeft: 2,
  backgroundColor: props.selected ? 'white' : '#7ea1ff',
  fontWeight: 'bold',
  fontSize: 20,
}));

interface FontTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function FontTabPanel(props: FontTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`font-mode-tabpanel-${index}`}
      aria-labelledby={`font-mode-tab-${index}`}
      {...other}
      style={{ minHeight: 370 }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function TabsContainer(props: {
  onMessage?: (success: boolean, message: string) => void;
}) {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isIOS, setIsIOS] = useState<boolean>(false);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  // Detect iOS and screen size
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']);
    setIsSmallScreen(window.innerWidth <= 900);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const a11yProps = (index: number) => ({
    id: `font-mode-tab-${index}`,
    'aria-controls': `font-mode-tabpanel-${index}`,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Box
        style={{
          width: '100%',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        {/* Conditionally render tabs and panels */}
        {isSmallScreen ? (
          !isIOS && (
            <TabSearchImage
              onMessage={props.onMessage}
              isSmallScreen={isSmallScreen}
              isIOS={isIOS}
            />
          )
        ) : (
          <>
            <Tabs
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
              value={tabIndex}
              onChange={handleChange}
              scrollButtons="auto"
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  backgroundColor: Colors.transparent,
                },
              }}
            >
              <FontTabMode label="이미지 검색" selected={true} {...a11yProps(0)} />
              <FontTabMode label="URL 검색" {...a11yProps(1)} />
              <FontTabMode label="폰트명 검색" {...a11yProps(2)} />
            </Tabs>

            <SwipeableViews
              index={tabIndex}
              onChangeIndex={handleChangeIndex}
              style={{
                marginLeft: 2,
                marginRight: 2,
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                //backgroundColor: Colors.white
              }}
            >
              <FontTabPanel value={tabIndex} index={0}>
                <TabSearchImage
                  onMessage={props.onMessage}
                  isSmallScreen={isSmallScreen}
                  isIOS={isIOS}
                />
              </FontTabPanel>
              <FontTabPanel value={tabIndex} index={1}>
                <TabSearchURL onMessage={props.onMessage} />
              </FontTabPanel>
              <FontTabPanel value={tabIndex} index={2}>
                <TabSearchFontName />
              </FontTabPanel>
            </SwipeableViews>
          </>
        )}
      </Box>

      <TabSearchBottom tabIndex={tabIndex} />
    </>
  );
}

export default TabsContainer;
