import { Box, styled as muiStyled, Typography } from '@mui/material';
import Colors from 'utils/Colors';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination } from 'swiper/modules';


const Section = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  textAlign: 'center',
  height: 'auto',
  aspectRatio: '375/667',
  maxHeight: '900px',
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
}));
const pagination = {
  clickable: true,
  renderBullet: function (index, className) {
    return '<span class="' + className + '">' + (index + 1) + '</span>';
  },
};
function MobileSectionTemplate(props: {
  id?: string;
  colored?: String;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  image?: React.ReactNode[];
}) {
  return (

    <Section
      id={props.id}
      style={{ backgroundColor: props.colored ? props.colored : Colors.white }}
    >
      <Typography
        component="h1"
        variant="h2"
        color={Colors.black}
        style={{
          fontWeight: 'bold',
          fontSize: 25,
          marginBottom: 10,
          lineHeight: 1.48,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: props.title ?? '' }} />
      </Typography>

      <Typography
        component="h1"
        variant="h2"
        style={{
          fontSize: 14,
          marginBottom: 26,
          lineHeight: 1.48,
          color: '#5c5c5c',
          fontWeight: 'normal',
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: props.subtitle ?? '' }} />
      </Typography>

      {/* Swiper for multiple images */}
      {props.image && props.image.length > 0 && (
        <Swiper
          pagination={pagination}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          spaceBetween={20} // Space between slides
          slidesPerView="auto" // Allows multiple slides to show
          centeredSlides // Center the active slide
          style={{
            width: '100%', // Ensures the swiper container spans the full width
            padding: '0 16px 30px 0', // Adds padding on both sides for partial visibility
          }}
        >
          {props.image.map((img, index) => (
            <SwiperSlide
              key={index}
              style={{
                width: '90%', // Adjust slide width to fit within the container
                maxWidth: '215px', // Maintain maximum width for images
                aspectRatio: '215/433', // Maintain aspect ratio
                //overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {img}
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      {props.children}
    </Section>
  );
}

export default MobileSectionTemplate;