
import image from 'assets/images/fontSearch_m@1x.png';
import image2x from 'assets/images/fontSearch_m@2x.png';
import image3x from 'assets/images/fontSearch_m@3x.png';
import MobileSectionTemplate from './MobileSectionTemplate';

function MobileFontSearchSection(props: { colored?: string }) {
  return <MobileSectionTemplate
    id='fontSearch'
    title={'폰트 검색'}
    subtitle={'홈에서 돋보기 화면을 클릭하면<br/> 검색 화면으로 이동해요.'}
    image={[< img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} alt={'폰트 검색'} />]} />;
};

export default MobileFontSearchSection;
