
import image1_1x from 'assets/images/imageSearch_m@1x.png';
import image1_2x from 'assets/images/imageSearch_m@2x.png';
import image1_3x from 'assets/images/imageSearch_m@3x.png';
import image2_1x from 'assets/images/imageSearch2_m@1x.png';
import image2_2x from 'assets/images/imageSearch2_m@2x.png';
import image2_3x from 'assets/images/imageSearch2_m@3x.png';
import image3_1x from 'assets/images/imageSearch3_m@1x.png';
import image3_2x from 'assets/images/imageSearch3_m@2x.png';
import image3_3x from 'assets/images/imageSearch3_m@3x.png';
import MobileSectionTemplate from './MobileSectionTemplate';

function MobileImageSearchSection(props: { colored?: string }) {
  return <MobileSectionTemplate
    id='imageSearch'
    title={'이미지 검색'}
    subtitle={'사진을 촬영해서 궁금한 폰트를<br />찾을 수 있어요.'}
    colored={props.colored}
    image={[
      <img src={image1_1x} srcSet={`${image1_2x} 2x, ${image1_3x} 3x`} alt={'이미지 검색'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />,
      <img src={image2_1x} srcSet={`${image2_2x} 2x, ${image2_3x} 3x`} alt={'이미지 검색'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />,
      <img src={image3_1x} srcSet={`${image3_2x} 2x, ${image3_3x} 3x`} alt={'이미지 검색'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />,

    ]} />;
};

export default MobileImageSearchSection;
