import { Container } from '@mui/material';
import { Box, styled as muiStyled } from '@mui/system';
import Copyright from 'components/footer/Copyright';
import Privacy from 'components/footer/Privacy';
import Terms from 'components/footer/Terms';
import styled from 'styled-components';
import Colors from 'utils/Colors';

const FooterContainer = muiStyled(Box)<{ mobile?: boolean }>(({ theme, ...props }) => {
  return {
    backgroundColor: Colors.disabled,
    width: '100%',
    padding: props?.mobile ? 28 : '28px 40px',
    color: Colors.black,


  }
});

const FooterList = styled.ul<{ mobile?: boolean }>`
  list-style-type: none;
  font-size: ${(props) => props?.mobile ? '12px' : '14px'};
  margin: 0px;
  padding: 0px;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content:space-between;
  flex-wrap:wrap;

  & > li {
   
    margin-left: ${(props) => props?.mobile ? '1rem' : '0.5rem'};
    text-align: right;
    vertical-align: middle;

    a {
     
      text-decoration: none;
      color: ${Colors.black};
    }
  }

  & li:first-child {
    text-align: left;
    margin-left: 0rem;
    font-weight: bold;
    min-width:50%;
    margin-right:auto;
  }
  & li:last-of-type {
    margin-left: ${(props) => props?.mobile ? 'auto' : '1rem'};
  }
`;

function Footer(props?: { mobile?: boolean }) {
  return <FooterContainer>
    <Container maxWidth='lg'>
      <FooterList >
        <li><Copyright /></li>
        <li><Box><Privacy mobile={props?.mobile} /></Box></li>
        <li><Box><Terms mobile={props?.mobile} /></Box></li>
      </FooterList>
    </Container>
  </FooterContainer>;
};

export default Footer;
