
import image from 'assets/images/urlSearch_m@1x.png';
import image2x from 'assets/images/urlSearch_m@2x.png';
import image3x from 'assets/images/urlSearch_m@3x.png';
import MobileSectionTemplate from './MobileSectionTemplate';

function MobileImageURLSearchSection(props: { colored?: string }) {
  return <MobileSectionTemplate
    id='imageURLSearch'
    title={'URL 검색'}
    subtitle={'이미지 주소(URL)를 입력하여<br />이미지에 포함된 폰트를 찾을 수 있어요.'}
    colored={props.colored}
    image={[
      <img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} alt={'이미지 주소 검색'} />
    ]}
  />;
};

export default MobileImageURLSearchSection;
