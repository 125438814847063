import { Button } from '@mui/material';
import ciBlue from "assets/images/ciBlue.png";
import ciBlue2x from "assets/images/ciBlue@2x.png";
import ciBlue3x from "assets/images/ciBlue@3x.png";
import axios from 'axios';
import Privacy from 'components/footer/Privacy';
import Terms from 'components/footer/Terms';
import ImageResult from 'components/image/ImageResult';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilState } from 'recoil';
import { UploadImageResultBox } from 'recoil/atom/UploadResultBoxState';
import styled from 'styled-components';
import { UploadResult } from 'types/UploadResult';
import { eventAnalytics } from 'utils/Analytics';
import Colors from 'utils/Colors';
import TabLoading from './TabLoading';
import { TabHintText, TabItem } from './TabStyle';
import { Typography, Box } from '@mui/material';
import TabsContainer from './TabsContainer';

const TabCIImage = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 3px;
`
export const TabBottomItem = styled.div<{ isAndroid: boolean }>`
  width: calc(100% - 16px);
  font-size: 12px;
  text-align:left;
  padding: 15px 0px 0 0px;
  margin-left: 16px;
  list-style:disc;
  display:list-item;
  color:  ${Colors.white};
  & > a {
    color: ${Colors.white};
    text-decoration:underline;
    text-underline-position:bottom;
  }
`;

const TabItemContainer = styled.div<{ paddingTop: number }>`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:40px 16px 20px;
  border-bottom-left-radius:12px;
  border-bottom-right-radius:12px
`;



function TabSearchImage(props: {
  onMessage?: (success: boolean, message: string) => void,
  isSmallScreen?: boolean; // 부모에서 전달받은 prop
  isIOS?: boolean;         // 부모에서 전달받은 prop
}) {
  const [result, setResult] = useRecoilState(UploadImageResultBox);
  const { isSmallScreen, isIOS } = props;

  const [isAndroid, setIsAndroid] = useState<boolean>(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || "";
    setIsAndroid(/android/i.test(userAgent));
  }, []);

  const onDrop = useCallback(async (acceptedFiles: any) => {

    setLoading(true);

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    const response = await axios.post<UploadResult>('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    setTimeout(function () {
      setLoading(false);

      if (response.data.success && response.status === 200) {
        if (response.data.result) {
          // setResult(response.data.result);
          openPopup(response.data.result?.image_url);
        }

      } else {
        props.onMessage?.(false, `파일에서 글씨를 찾지 못했습니다. 글씨가 포함된 이미지로 검색해주세요.
        (10MB 이하의 JPG, JPEG, PNG, BMP 파일 지원)`);
      }

      eventAnalytics('search_font', { 'method': 'img' });

    }, 500);
  }, [props, setResult]);

  const openPopup = async (url: string) => {
    const popup = window.open('', '_blank');

    setTimeout(function () {
      if (!popup) {
        alert('팝업이 차단되었습니다. 차단을 해제해 주세요.');
        return;
      }

      try {
        const imageUrl = encodeURIComponent(url);
        popup.location.href = `${process.env.REACT_APP_API_SEARCH_URL}?image_url=${imageUrl}`;
      } catch (error) {
        popup.close();
        alert('팝업이 차단되었습니다. 차단을 해제해 주세요.');
      }
    }, 500);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {
        loading ?
          <TabLoading message={'이미지에서 폰트를 찾는 중입니다.'} /> : <>
            <>
              <input {...getInputProps()} />
              <TabItemContainer className="borderTopRadius"
                paddingTop={(result && result.text_boxes?.length) ? 75 : 5}
                style={{
                  opacity: isDragActive ? 0.2 : 1,
                  backgroundColor: isDragActive ? Colors.disabled : Colors.white,
                  borderTopRightRadius:
                    isSmallScreen && !isIOS ? '12px' : undefined,
                  borderTopLeftRadius:
                    isSmallScreen && !isIOS ? '12px' : undefined,
                }}
              >
                {(result && result.text_boxes?.length) ? <>
                  <ImageResult result={result} />
                </> : <div style={{ width: '100%' }} {...getRootProps()}>
                  <TabItem style={{ margin: '0 auto 30px' }} ><TabCIImage src={ciBlue} srcSet={`${ciBlue2x} 2x, ${ciBlue3x} 3x`} /></TabItem>
                  <Typography component='h1' variant='h2' style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.48, color: '#1c1c1c' }}>이미지 검색</Typography>
                  <TabItem style={{ margin: '4px auto 40px' }} ><Typography style={{ fontSize: 14, lineHeight: 1.61, color: '#1c1c1d' }}>찾고 싶은 폰트가 담긴 <br />이미지를 추가해주세요</Typography></TabItem>
                  <TabItem style={{ marginBottom: 0 }}><Button variant='contained' style={{ border: `1px solid ${Colors.sandollColor}`, backgroundColor: '#fff', color: Colors.sandollColor, borderRadius: '8px', width: '100%', height: 48 }} disableElevation>이미지 첨부</Button></TabItem>
                </div>}
              </TabItemContainer>
              {(result && result.text_boxes?.length) ? <></> :
                <TabBottomItem isAndroid={isAndroid}>이미지를 업로드하시면 폰트폰트의 <Privacy /> 및 <Terms />에 동의하는 것으로 간주됩니다.</TabBottomItem>}
            </>
          </>
      }
    </div >
  )
}

export default TabSearchImage;