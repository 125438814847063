
import { Box, Button, Link, styled as muiStyled, Typography } from '@mui/material';
import backgroundHeader from "assets/images/mobileBackgroundHeader.png";
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilState } from 'recoil';
import { UploadImageResultBox } from 'recoil/atom/UploadResultBoxState';
import { UploadResult } from 'types/UploadResult';
import { eventAnalytics } from 'utils/Analytics';
import Colors from 'utils/Colors';

const Wrapper = muiStyled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${backgroundHeader})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'bottom',
    color: theme.palette.primary.main,
    textAlign: 'center',
  }
});

const Section = muiStyled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: 'auto',
    aspectRatio: '375/740',
    maxHeight: '740px',
    margin: '0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  }
});

const DownloadButton = muiStyled(Button)(({ theme }) => {
  return {
    background: Colors.white,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 20,

    '&:hover': {
      background: Colors.white,
      color: theme.palette.primary.main,
    }
  }
});

// const onDrop = useCallback(async (acceptedFiles: any) => {

// }, [props, setResult]);

function MobileMainSection(props: {}) {
  const [storeLink, setStoreLink] = useState<String>(process.env.REACT_APP_PLAYSTORE);
  const userAgent = navigator.userAgent;
  const isIOS = (/iPad|iPhone|iPod/.test(userAgent)); // (/android/i.test(userAgent)

  useEffect(() => {
    // const userAgent = navigator.userAgent;
    // const isIOS = (/iPad|iPhone|iPod/.test(userAgent)); // (/android/i.test(userAgent)
    if (isIOS) {
      setStoreLink(process.env.REACT_APP_APPSTORE);
    }

  }, []);

  //#region 이미지 검색 
  const [result, setResult] = useRecoilState(UploadImageResultBox);

  const onDrop = useCallback(async (acceptedFiles: any) => {

    setLoading(true);

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    const response = await axios.post<UploadResult>('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    console.log(response);

  }, [props, setResult]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const [loading, setLoading] = useState<boolean>(false);
  //#endregion


  return <Wrapper id='home'>
    <Section>
      <Typography
        component='h1'
        variant='h2'
        color={Colors.white}
        style={{ fontWeight: 600, fontSize: 30, marginBottom: 30, lineHeight: 1.42 }}
      >
        폰트가 궁금할 땐 <br />폰트폰트
      </Typography>

      {isIOS ? (
        <DownloadButton variant='contained' disableElevation>
          <Link
            href={storeLink}
            onClick={() => { eventAnalytics('click_storebanner_m'); }}
            target={'_blank'}
            style={{ textDecoration: 'none' }}>앱 다운로드</Link>
        </DownloadButton>
      ) :
        <div  {...getRootProps()}>
          <input {...getInputProps()} />
          <button>파일 선택하기</button>
        </div>
      }
    </Section>
  </Wrapper>;
};

export default MobileMainSection;
