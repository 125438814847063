import Loader from 'components/Loader';
import { TabHintText, TabItem, TabItemContainer } from './TabStyle';

function TabLoading(props: { message?: string }) {
  return (
    <TabItemContainer style={{ backgroundColor: '#fff', borderRadius: '12px' }}>
      <TabItem><Loader /></TabItem>
      <TabItem><TabHintText>{props.message ?? '이미지에서 폰트를 찾는 중입니다.'}</TabHintText></TabItem>
    </TabItemContainer>
  )
}

export default TabLoading;
